@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalnia:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:wght@400;700&display=swap');

html {
  overflow: overlay;
  overflow-y: scroll;
}


body {
  font-family: 'Jost', sans-serif;
  /* font-family: 'Courier New', monospace; */
  background-color: #444444;
  /* padding: 20px; */
  /* padding-top: 50px; The height of the header */
  color: #333;
}
@media only screen and (max-width: 768px) {
  body {
    padding: 0px;
    padding-top: 40px;
    margin: 0px;
  }
}

::-webkit-scrollbar {
  height: 1rem;
  width: .5rem;
}
::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: rgba(217,217,227,.8);
  border-color: rgba(255,255,255,var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}


.MuiTabs-flexContainer {
  justify-content: space-around; /* or space-around */
  font-family: 'Jost', sans-serif;
  
}

/* Styling for custom-tab class */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #00A6ED;
}
.css-1aquho2-MuiTabs-indicator {
  /* other styles remain unchanged */
  background-color: #00A6ED !important;
}

.MuiTab-root.custom-tab {
  font-size: 1rem; /* Default font size for larger screens */
  font-family: 'Jost', sans-serif;
  padding: 0px;
  font-size: .8rem;
}

@media only screen and (max-width: 600px) {
  .MuiTab-root.Mui-selected.custom-tab,
  .MuiTab-root.custom-tab {
    font-family: 'Jost', sans-serif;
    font-size: .7rem;
    padding: 5px;
  }
}

/* Desktop styles */
.site-header {
  width: 100%;
  font-family: 'Courier New', monospace;
  box-sizing: border-box;
  height: 50px;
  padding: 0 25px;
  background-color: #000000;
  color: white;
  box-shadow: 0 3px 5px rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.header-content {
  font-family: 'Courier New', monospace;
  display: flex;
  align-items: center;
  width: 100%;
}

.header-favicon {
  width: 30px; /* Adjust the size as needed */
  height: 43px; /* Adjust the size as needed */
  margin-right: 8px; /* Adds some space between the icon and the text */
  margin-left: -10px;
  margin-bottom: 5px;
  vertical-align: middle; /* Aligns the icon with the middle of the text */
}

.header-title {
  margin-top: 10px;
  font-size: 1.1rem;
  color: white;
  /* Ensure the title is aligned left on desktop */
 
}

.header-icon {
  color: lightgrey;
  cursor: pointer;
  /* Position the icons absolutely within the header */
  position: absolute;
}

/* Styling for the hamburger menu container */
.hamburger-menu {
  margin-left: -10px;
  cursor: pointer;
  display: inline-block;
  width: 20px; /* Adjust size as needed */
  left: 15px;
  display: none;
}

/* Styling for each line of the hamburger menu */
.hamburger-menu span {
  background-color: white; /* Adjust color as needed */
  display: block;
  height: 3px; /* Adjust thickness as needed */
  margin: 6px 0; /* Adjust spacing as needed */
  transition: all 0.3s ease-in-out;
}

/* Styling for the 'X' transformation */
.hamburger-menu.change span:first-child {
  transform: translateY(4px) rotate(45deg);
}

.hamburger-menu.change span:nth-child(2) {
  transform: translateY(-5px) rotate(-45deg);
}


.header-icon.right {
  /* Align right icon (user icon?) to the right */
  right: 25px;
}

/* Mobile styles */
@media only screen and (max-width: 768px) {
  .site-header {
    justify-content: flex-start; /* Align items to start (left) */
    padding-left: 50px; /* Make room for the hamburger menu */
  }

  .header-title {
    /* Center the title in the available space */
    margin: 0 auto;
  }
  
  .header-favicon {
display: none;
  }

  .hamburger-menu {
    display: inline-block;
  }

  .sidebar {
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%); /* Hidden by default */

  }

  .sidebar.visible {
    transform: translateX(0); /* Show sidebar when toggled */
  }

  .user-tray {
    width: 250px;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #f4f4f4;
    border-left: 1px solid #ccc;
    padding: 20px;

      z-index: 10000;
      background-color: red; /* Temporarily set a bright color */
    
  }
  
  

  .dim {
    opacity: 0.5; /* Adjust opacity to your liking for the dimming effect */
    transition: opacity 0.3s; /* Smooth transition for the dimming effect */
  }

  .welcome-text {
    display: none;
  }
}

.App {
  font-family: 'Jost', sans-serif;
  display: flex;
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  justify-content: center; /* Added this to center the child elements */
}


.sidebar {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  min-width: 188px;
  /* width: 175px; */
  font-size: .9rem;
  background-color: #F1F1F1; /*rgb(236, 236, 236);*/
  color: black;
  padding: 0px 0px; /*sides must be 0 so that selected p highlight can reach width*/
  border-right: 1px solid #ccc;
  overflow-y: auto;
  height: calc(100vh - 50px); /* Adjust the height to account for the fixed header */
  position: fixed; /* Keeps the sidebar fixed on the screen */
  top: 50px; /* Starts the sidebar 50px from the top to account for the header */
  left: 0; /* Aligns the sidebar to the left */
  z-index: 1;
  padding-bottom: 100px;
}

@media only screen and (max-width: 900px) {
  .sidebar {
    background-color: none; 
  }
}


.header {
  /* color: orange; */
  text-align: center; /* Center the header text instead of using <center> tag */
  position: sticky;
  top: 0; /* The header will stick to the top of the sidebar */
  z-index: 1000; /* Ensure the header is above other content */
  background-color: inherit; /* Match the sidebar background */
  padding: 5px; /* Add some spacing around the header */
  margin-top: 0; /* Remove default margins */
  /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); Optional: Add shadow for better separation */
}

.header h2 {
margin: 5px;
}

p {
  display: block;
  margin: 10px 3px; 
}

.sidebar {
  cursor: pointer;
 /* top/bottom | left/right <- move this one to adjust sidebar width */
  margin: 0;  /*Remove default margins */
}


.week-number-label {
  color: red;
  font-size: 1.2rem;
  padding: 0px 5px;
  /* background-color: #cecdcd; */
}

.form-group {
  display: flex; /* This will lay out the label and button next to each other */
  align-items: center; /* This will vertically align the label and button */
  gap: 10px; /* Adjust the space between the label and button */
}

.main-content {
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.5)), url('https://t3.ftcdn.net/jpg/02/05/83/98/240_F_205839891_H0YlsXWinlJfZ8WHvHnciygDfnO3Uhib.jpg');
  background-size: 110%;
  background-repeat: repeat;
  border: 1px solid #e5e5e5;
  padding: 10px 35px 25px;
  box-shadow: 0 10px 15px rgba(0,0,0,0.2);
  border-radius: 5px;
  flex: 6;
  max-width: 800px;
  /* min-height: 50vh; */
  margin-top: 50px;
}



#entry {

  padding: 30px;
}
.entry {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; Ensures icon and title are aligned vertically */
  text-align: left;
}

.entry:hover {
  background-color: #e8e8e8;
}

.entry-icon {
  color: #00A6ED;
  font-size: .9rem;
  margin: 0.4rem .5rem; /* Keep margin on top and bottom */
  padding-top: 0; 
}

.entry-title {
  margin: 0.5rem 0 0.5rem .15rem; /* Adjust margin if needed */
  cursor: pointer;
  white-space: nowrap; /* Prevent title text from wrapping */
}

.search-container {
  display: flex;
  justify-content: center; /* Centers search button horizontally */
  margin-bottom: 10px; /* Add space below the search button */
}

.toggle-search-button {
  margin: 0;
  border: none;
  cursor: pointer;
  font-size: .75rem; /* Adjust size if needed */
}

.fa-stack {
  display: inline-block;
  position: relative;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fa-inverse {
  color: black; /* Adjust color as needed */
}


.selected{
  background-color: #e0e0e0; /* or any other styling you prefer */
}

.selected:hover{
  background-color: #e0e0e0; /* or any other styling you prefer */
}

.save-entry-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  /* justify-content: space-between; Creates space between the button and the status text */
  width: 100%; /* Ensure the container spans the full width */
}

.saveButton {
  border: 1px solid black;
  border-radius: 20px;
  background-color: #f0efef;
  transition: background-color 0.5s;
  padding: 1px 5px;
  cursor: pointer;
}


.saveButton:hover {
  background-color: #e4e6fc;
}

/* Add some right margin to the button if needed */
.save-entry-container button {
  margin-right: 10px;
}

.save-entry-container span {
font-size: 0.8em;
color: darkslategray;
}

.charCountDisplay {
  text-align: right;
  margin-top: 5px;
  margin-bottom: 10px;
  color: grey;
  font-size: 0.8em;
}

.link-nodec {
  text-decoration: none; /* Removes the underline from the link */
  color: inherit; /* Optional: ensures the link color matches surrounding text */
}

.link-nodec:hover, .link-nodec:focus {
  text-decoration: underline; /* Optional: adds underline on hover for visual feedback */
  color: #007bff; /* Optional: changes color on hover; replace with desired color */
}

.custom-modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; /* Full height */
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  background-color: rgba(0,0,0,0.4); /* Black with opacity */
}

.custom-modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 500px; /* Adjust width as necessary */
  border-radius: 5px; /* Optional: for rounded corners */
  margin: 0 15px;
}


.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.custom-modal-footer button {
  margin-left: 10px;
}


/* Style for the delete icon button if needed */
.deleteIcon {
  font-size: 1.1rem;
  flex: 1; /* Allows the span to grow and fill the space, pushing the delete icon to the right */
  text-align: right; /* Aligns the text to the right */
  align-self: flex-end;
  color: rgb(158, 0, 0);
  border: none;
  /* margin-top: 10px; */
  background-color: transparent;
  cursor: pointer;
}

.charsUsed {
  flex: 1; 
  text-align: right; /* Aligns the text to the right */
  align-self: flex-end;
  font-size: .9rem;
}


@media only screen and (max-width: 768px) {
  .main-content {
    padding: 10px 15px 25px 15px;
    margin-top: 10px;
    background-size: 175%;
  }
}

.menu {
  background-color: #333;
  color: white;
  position: fixed;
  top: 50px;
  right: 0;
  width: 250px;
  z-index: 999;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  padding: 10px;
  cursor: pointer;
}

.menu ul li:hover {
  background-color: darkgray;
}

h1 {
  font-family: 'Jost', sans-serif;
  font-size: 24px;
  margin-bottom: 0px;
  color: #777676;
  font-weight: normal;
}

h2 {
  font-family: "DM Serif Display",sans-serif;
letter-spacing: .5px;
  font-size: 20px;
  margin-bottom: 16px;
  font-weight: bold;
  color: #555;

}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}


textarea {
  /* background-color: transparent; */
  font-family: 'Roboto', sans-serif;
  font-size: .9em;
  background-color: rgba(255, 255, 255, 0.50);
  width: 100%;
  box-sizing: border-box;
  height: 300px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  margin-bottom: 20px;
  /* box-shadow: inset 0 1px 3px rgba(0,0,0,0.1); */
  resize: vertical;
}

textarea:focus {
  outline: none; /* Removes default outline */
  outline: none;
  /* border: 5px solid rgb(211, 41, 11); Remove the border */
  border: none;
box-shadow: 0 0 0 .6px rgb(48, 152, 236);   /* Simulate a 0.5px border */
}

.new-entry-button {
  font-size: 1em;
  margin: 10px auto 10px auto;
  display: block; /* or inline-block, if you define a width less than the container's width */
  box-sizing: border-box;
  background-color: #F1F1F1;
  color: darkslategrey;
  /* dark button option
  background-color: #646465;
  color: white; */
  border: 1px solid #ccc;
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  /* Ensure the button has a width less than its container */
  width: 75%; /* Adjust this as needed for your design */
}

.new-entry-button:hover {
  background-color: #E9F1FE;
  color: black;
  /* font-weight: bold; */
}

.guiButton {
  color: black;
  margin: 45px auto 40px auto;
 /* top and bottom margin 15px, left and right margin auto */
 font-family: 'Jost', sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  width: 70%;
  box-sizing: border-box;
  background-color: #E9F1FE;
  border: 1px solid #ccc;
  padding: 5px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 2s ease, box-shadow 2s ease, width 2s ease, font-size 1.5s ease;
  display: block;
}


.guiButton:hover {
  background-color: white;
  font-weight: normal;
  font-size: 1.5em;
  color: black;
  box-shadow: -1px 1px 6px rgba(110, 109, 109, 0.3); /*Subtle shadow*/
  width: 75%;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#gptForm {
  
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the flex container */
}

.hero-image img {
  width: 100%;
  height: auto;
  display: block;
  
}
#gptForm p {
/* font-size: 1.2rem; */

}

/* Chapter Titles */
#gptForm h2 {
  /* //color: #333333; */
  font-weight: normal;
  font-size: 2.2rem;
  margin-bottom: 0px;
  border-bottom: 1px solid #CD853F;
  padding: 0rem 0rem 0em 0rem;
  width: 100%;
  /* margin-block-start: 0em;
  margin-block-end: 0em; */
}

#gptForm h2::after {

}


#gptForm h3 {
  /* //color: #333333; */
  
  font-weight: normal;
  font-size: 1.4rem;
  margin-bottom: 0px;
  /* border-bottom: 1px solid #CD853F; */
  padding-bottom: 0px;
}

#gptForm h4 {
  /* //color: #333333; */
  font-weight: bold;
  font-size: 1.4rem;
  margin: 0px;
  /* border-bottom: 1px solid #CD853F; */
  padding: 10px 0px;
}


/* .user-answer:before{
  font-size: 1em;
  color: darkgray;
  content: "Your Introspection:";
  display: block;
  margin-bottom: 10px;  
  font-weight: bold;    
} */



.user-answer{
  font-size: 16px;
  margin-bottom: 20px;
  padding: 25px;
  margin: 0px 0px 50px;
  padding: 15px 20px 10px;
  
  border-radius: 10px;

  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.50);
  color: darkred; 
  font-size: 1.1rem;
  line-height: 1.3rem;
}

#gptForm #content {
  /* color: #07253d;  */
  /* background-color: #ffffff; */
  font-size: 1.2rem;
  padding: 5px 0px;
  border-radius: 5px; 
}



#gptForm ul::before {
  color: darkgray;
  font-size: 1.4em;
  content: "Try these...";
  display: block;
  margin: 0px 0px 20px 40px;  /* Adjust as needed for spacing */
  font-weight: normal;    /* Optional, to make the "Tips:" text bold */
}

#gptForm ul {
  /* //color: #4682B4; //B0C4DE //#6A5ACD //#4682B4   */
  /* background-color: rgb(255, 255, 255); */
  list-style: none; /* Remove default list styling */
  background-color: #fafafa;
  line-height: 1.3em;
  border: 1px solid #4682B4; /*#CD853F*/
  border-radius: 10px; 
  margin: 25px 10px 30px 10px;
  padding: 20px 25px 15px 20px;
  font-size: 1rem;
  /* background-color: rgb(58, 156, 156);
  color: white; */
  /* background-color: #F1F1F1;
  color: rgb(58, 58, 58); */
  }

#gptForm li {
  /* margin: 0px 10px 25px 10px; */
  position: relative; /* Needed to position the pseudo-element correctly */
  margin-bottom: 10px; /* Space between list items, adjust as needed */
  padding: 7px 0px;
  padding-left: 60px; /* Adjust as needed to space text from the icon */

}

#gptForm li i {
  color: #00A6ED;
  position: absolute;
  left: 0; /* Align icons to the left */
  top: 45%; 
  transform: translateY(-50%); /* Ensure it's centered */
  width: 25px; /* Space for the icon */
  text-align: center; /* Center the icon in the allocated space */
  font-size: 1.8em; /* Icon size, adjust as needed */
}

/* Labels */
#gptForm label {
  /* font-weight: bold; */
  font-size: 1em;
  display: inline-block;
  margin-bottom: 10px;
}

/* first question */
#gptForm .qi-label {
  padding-top: 20px;
  font-size: 1.5em;
  margin: 0;
  margin-right: 10px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Initial state of the button, invisible */
#helpButton {
  background-color: rgb(161, 161, 161);
  color: rgb(228, 228, 228);
  padding: 0px 10px 2px 10px;
  cursor: pointer;
  /* margin: 0px 10px 0px 0px; */
  margin-top: 17px;
  border: 1px solid #4682B4;
  border-radius: 10px;
  opacity: 0; /*Button is initially invisible */
  transition: background-color .7s, color .7s;
  /* transition: opacity 0.5s ease-in-out; Transition effect for the opacity property */
}

#helpButton.visible {
  /* opacity: 1; Button is fully visible when the 'visible' class is applied */
  /* animation: fadeIn .7s 2s ease-in-out forwards; Animation to fade in the button */
}
#helpButton.visible:hover {
  background-color: #e4e6fc;
  color: black;
}

/* Inspirational Quote */
#gptForm #quote {
  font-size: 1.3em;
  /* font-style: italic; */
  color: #9a9a9a;
  padding-top: 3px;
  margin-left: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 15px;
  border-left: 7px solid #CD853F;
  padding-bottom: 3px;
}

/* 
//this is for "add to quotes" 
#gptForm #quote:hover {
  border: 1px solid #4682B4;
  border-radius: 10px;
  background-color: rgba(242, 251, 254, 0.5);
  cursor: pointer;
} */

/* #gptForm .bookRec::before {
  color: red;
  font-size: 1em;
  content: "**UNDER CONSTRUCTION**";
} */

#gptForm .bookRec {
  font-size: 1rem; /* Responsive font size */
  background-color: rgba(242, 251, 254, 0.5); /* Slightly transparent background */
  line-height: 1.5; /* Increased line height for readability */
  border: 1px none #4682B4;
  border-radius: 10px;
  margin: 70px 0; /* Responsive margin */
  padding: 1rem 1.5rem 1.5rem 1.5rem; /* Uniform padding */
  color: rgb(58, 58, 58);
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
}


@media (max-width: 768px) {
  .bookRec {
    margin: 1rem 0; /* Adjust margin for smaller screens */
    padding: 0rem 1rem 1rem 1rem; /* Adjust padding for smaller screens */
  }
}

#gptForm .bookRec h2{
font-size: 2rem;
color: rgb(183, 182, 182);
/* border-bottom: none; */
margin: 0px 0px;
padding: 0px;
}

#gptForm .bookRec h4{
  /* //color: #333333; */
  font-weight: normal;
  color: black;
  font-size: 1.4em;
  margin: 0px;
  /* border-bottom: 1px solid #CD853F; */
  padding: 10px 0px;
  }
  /* Additional CSS rule for hyperlinks */

#gptForm .bookRec a{
  font-size: 1.3rem;
  color: #0000FF; /* Bright blue color for links */
  text-decoration: none; /* Removes underline from links; optional based on your design */
  padding: 0px 0px;
  display: inline-block;
  }


/* CSS rule for hyperlinks on hover to maintain interactivity */
#gptForm .bookRec a:hover {
  text-decoration: underline; /* Adds underline on hover for better UX */
}


  #gptForm .bookRec p{
    color: rgb(53, 53, 53);
margin-bottom: 30px;
    }
/* first textarea */
#gptForm #Qi {  /* Note: More specific selector to override general styles */
    height: 20em;  /* Change the height */
    /* //width: 97%; */
    /* Add any other styles you want to override */
}

#gptForm .affiliate-disclaimer{
  color: #666; /* Example: sets the text color */
  font-size: 12px; /* Example: sets the font size */
  margin: 10px 0; /* Example: sets the margin around the paragraph */
  margin-bottom: 0px !important;
  font-style: italic;
  /* Add more styling as needed */
}



#gptForm textarea {
  /* //opacity: 0.75; */
  font-size: 16px;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 12px;
  border: 1px solid #ccc;
}

@media only screen and (max-width: 768px) {
  #gptForm #content {
padding: 0px;
  }

  #gptForm h2 {
font-size: 1.6rem;
  }
  #gptForm h3 {

    font-size: 1.4rem;
  }
}

.privacy-policy-container {
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9; /* Light background for the content */
  border-radius: 8px; /* Slightly rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-family: 'Roboto', sans-serif; /* Assuming Roboto is used throughout the app */
  max-width: 800px;
}
.h1-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
  height: 100%; /* Or set to the desired height */
}

.privacy-policy-container h1 {
  color: #333; /* Dark color for the main heading */
  margin-bottom: 16px; /* Space after the heading */

}

.privacy-policy-container h2 {
  color: #666; /* Slightly lighter color for subheadings */
  margin-top: 20px; /* Space above subheadings */
  margin-bottom: 10px; /* Space below subheadings */
}

.privacy-policy-container p {
  color: #333; /* Dark color for text to ensure readability */
  line-height: 1.6; /* Spacing for readability */
  margin-bottom: 10px; /* Space between paragraphs */
}
.terms-of-service-container{
  margin: 20px auto;
  padding: 50px;
  background: #f9f9f9; /* Light background for the content */
  border-radius: 8px; /* Slightly rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-family: 'Roboto', sans-serif; /* Assuming Roboto is used throughout the app */
  max-width: 800px;
}

.terms-of-service-container h1 {
  font-size: 2rem;
  color: #333; /* Dark color for the main heading */
  margin: 0px auto;
  margin-bottom: 5px; /* Space after the heading */
  
}

.terms-of-service-container h2 {
  font-size: 1.1rem;
  color: #666; /* Slightly lighter color for subheadings */
  margin-top: 5px; /* Space above subheadings */
  margin-bottom: 5px; /* Space below subheadings */
}

.terms-of-service-container p {
  color: #333; /* Dark color for text to ensure readability */
  line-height: 1.6; /* Spacing for readability */
  margin-bottom: 10px; /* Space between paragraphs */
}
.terms-of-service-container ul {
margin: 0px;
padding-left: 25px;
line-height: 1.6;
}
#acceptTOS{
  margin: 1rem .5rem 1.5rem;
}

.button-disabled{
font-size: 1rem;
  padding: 5px 20px;
}
.button-enabled{
  font-size: 1rem;
  padding: 5px 20px;
  cursor: pointer;
}
textarea::placeholder {
        color: #aeaeae;
      }

      textarea:focus {
  outline: none; /* Removes default outline */
  /* border: 1px solid blue; Set border to 1px and color to blue */
}
.landing-image {
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 35%;
  min-width: 250px;
  height: auto;
}

.landing-container {
  font-family: 'Jost', sans-serif;
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  /* max-width: 680px; */
  min-height: 95vh;
  width: 100%;
  font-size: 1.1rem; /* Adjusted with clamp below */
}

.left-column, .right-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.left-column {
  background-color: #FCFBF3;
}

.right-column {
  justify-content: center;
  background-color: #BCBCC4;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button-container::before,
.button-container::after {
  content: '';
  background-color: #000;
  height: 2px;
  flex-grow: 1;
  margin: 60px 75px;
  margin-bottom: 25px;
}

.landing_login_cta {
  font-size:1.2rem; /* clamp(1rem, 2.5vw, 1.2rem); Responsive font size */
  font-family: 'DM Serif Display', sans-serif;
  width: 30%;
  min-width: 250px;
  padding: 20px 0px;
  margin-top: 40px;
  margin-bottom: 5px;
  background-color:  #E9F1FE;
  color: black;
  text-decoration: none;
  border: 1px solid black;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, color 1s, width 1s, font-size 1s;
}

.landing_login_cta:hover {
  background-color:white;
  color: darkblue;
  /* text-decoration: underline; */
width: 31%;
font-size:1.25rem;
}



#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 600px; 
  /* Rest of your styles */
}

.collapsed {
  height: 20px; /* Or the height of your 'border' */
  overflow: hidden;
  cursor: pointer; /* Change cursor to indicate the video can be clicked */
}

.expand-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  /* background: url('path_to_expand_icon.png') no-repeat center center; */
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.collapsed .expand-icon {
  display: block;
}


.landing-container h1 {
  color: darkslategray;
  font-weight: 300;
  font-family: 'DM Serif Display', sans-serif;
  font-size: clamp(2.5rem, 5vw, 3.5rem); /* Responsive font size */
  margin: 0px;
  margin-top: 15px;
}
.landing-container h3 {
  font-family: 'DM Serif Display', sans-serif;
  font-size: clamp(1rem, 5vw, 1.5rem); /* Responsive font size */
  font-weight: normal;
  color: darkblue;
  margin-top: 0px;
  margin-bottom: 0px;
}
.landing-container p {
padding: 0px 50px;
color: darkblue; 
}

.terms-privacy-container {
  font-family: 'Roboto', sans-serif;
  text-align: center; /* Center the links */
  margin: 20px 0px 50px; /* Add space at the top, adjust as needed */
}
.back-button {
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: bold;
}

.link-style {
  cursor: pointer;
  font-size: .8rem;
  font-weight: normal; /* Adjust font weight as needed */
  text-decoration: underline; /* Underline the link */
  margin: 300px 10px 0px; /* Add some horizontal space around the links */
  color: gray; /* Set the color of the links */
}

.separator {
  color: gray; /* Color of the separator, adjust as needed */
  padding: 0px 15px;
}

/* Feature List Styles */
.feature-list {
  background-color: rgb(245, 243, 243);/*#fafafa;*/
  line-height: 1.3em;
  border: 1px solid #4682B4; /*#CD853F*/
  border-radius: 10px; 
  margin: 25px 15px 30px 15px;
  padding: 15px 10px 15px 25px;
  font-size: 16px;
  /* width: 100%; */

  /* list-style: none;
  padding: 0;
  margin: 20px 0; */
}

.feature-list li {
  /* background: #f4f4f4; Soft background color */
  color: darkblue;
  /* border-left: 4px solid #9c27b0; Brand color for the bullet line */
  margin-bottom: 10px;
  padding: 10px 15px;
  margin-left: 25px;
  border-radius: 4px; /* Slight rounding of corners */
  transition: transform 0.3s ease-in-out;
  align-items: left;
  text-align: left;
}

.feature-list li:hover {
  /* transform: translateX(10px); Slight movement to indicate interactivity */
}

.feature-title {
  font-weight: bold;
  color: darkblue; /* Dark color for text */
  margin-right: 5px;
}
.login-link {
  cursor: pointer;
  text-decoration: underline;
  color: inherit; /* Inherits the color from the parent element */
}

.login-link:hover, .login-link:focus {
  text-decoration: underline; /* Adds underline on hover for visual feedback */
  color: #007bff; /* Changes color on hover; adjust as needed */
}

.landing-container p.already-joined{
color: black;
font-size: .8rem;
margin:0;
} 

.landing-container p.important-text {
  color: red; /* This will now take precedence over .landing-container p */
  /* margin-top: 0px; */
}

/* Responsive design considerations */
@media (max-width: 768px) {

  .landing_login_cta {
    width: 99%;

  }
  .landing-image {
    max-width: 90%;
  }

.button-container::before,
.button-container::after {
  content: none; /* Remove the content */
  margin: 0;     /* Reset margins */
  /* Add any other style resets or modifications for mobile here */
}


  .landing-container {
    font-family: 'Jost', sans-serif;
    margin-top: 10px;
    flex-direction: column;
    font-size: clamp(0.9rem, 4vw, 1.1rem); /* Adjust the font size for mobile */
  }


  .left-column, .right-column {
    box-sizing: border-box;
    height: 50vh;
    width: 100%;
    justify-content: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.css-ahj2mt-MuiTypography-root {
  /* font-family: 'Jost', sans-serif !important; */
  /* Other properties like margin, font-weight, etc., can remain the same if needed */
}

.quill {
  width: 100%;
  resize: vertical;
  /* display: flex; */
}

.ql-toolbar svg {

  width: 14px;    /* Set the width of the icon */
  height: 14px;   /* Set the height of the icon */
}

.ql-toolbar .ql-indent svg {
  fill: #4A90E2;  /* Change the color of the icon */
  
}

.ql-toolbar.ql-snow {
  padding: 3px 5px;
  background: rgb(239, 239, 239);
  /* border-bottom: 1px solid #ccc; */
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
}

.ql-toolbar button {
  margin-right: 5px;
  border: none;
  background: none;
  padding: 10px;
  border-radius: 5px;
  
}

.ql-toolbar button:hover {
  background: #e2e2e2;
}

.ql-toolbar .active {
  border-bottom: 2px solid blue;
}

.ql-editor {
  border: none;
  padding: 12px;
  min-height: 100px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.50);
  resize: vertical;
  overflow: auto; /* Add overflow auto for better handling of content */
}

.ql-container.ql-snow {
  width: 100%;
  height: auto; /* Adjust height dynamically based on content */
  min-height: 100px; /* A minimum height to prevent collapse */
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex; /* Ensure it's flexible */
  flex-direction: column; /* Stack children vertically */
  font-size: 16px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
